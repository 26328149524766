import { type SupportedMarket, formatToMarketSpecificCurrency } from '@workbench/core';
/**
 * formatPrice formats the price to the correct format from AEM language/country, using workbench's formatToMarketSpecificCurrency
 * If the market specific currency is not available, format the price to the currency from the API response
 */
export function formatPrice(
  { country, language }: { country: string; language: string },
  { currency, price }: { currency: string; price: number },
): string {
  const locale = `${language}-${country}` as SupportedMarket;

  return (
    // try to format the price to the market specific currency
    formatToMarketSpecificCurrency(price, locale) ??
    // if the market specific currency is not available, format the price to the currency from the API response
    Intl.NumberFormat(locale, { style: 'currency', currency }).format(price)
  );
}
