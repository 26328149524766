import { wbBreakpoints } from '@dh-io-owpi/shared/src/composables/useMediaQuery';
import { type AkamaiOperators, applyAkamaiIM, getAkamaiIM } from '@dh-io-owpi/shared/src/utils/getAkamaiIM';
import { panic } from '@dh-io-owpi/shared/src/utils/panic';
import { modifyUrl } from '@dh-io-owpi/shared/src/utils/url';
import type { MediaQuery } from '@workbench/core';

export type ImageParams =
  | {
      y: number;
      width: number;
      // actual width of the background on the page.
      // we let BBD resize to this width to avoid overfetching.
      displayWidth?: number;
      height: number;
    }
  | { displayWidth: number };

export type ImageCroppingInfo = Record<MediaQuery, ImageParams>;

export const CAMPAIGN_BG_WIDTH = 3000;

export function getOptimizedAssetUrl(bgUrl: string, imageParams: ImageParams) {
  const akamaiOperators: AkamaiOperators[] = [];

  if ('y' in imageParams && 'width' in imageParams && 'height' in imageParams) {
    const { y, width, height } = imageParams;

    // calculate 'x' to center the crop
    const x = Math.round(CAMPAIGN_BG_WIDTH / 2 - width / 2);

    akamaiOperators.push(['Crop', x, y, width, height]);
  }

  if (imageParams.displayWidth) akamaiOperators.push(['Resize', imageParams.displayWidth]);

  if (akamaiOperators.length) return applyAkamaiIM(bgUrl, akamaiOperators);

  return panic('Invalid cropping parameters');
}

export function generateSrcSet(
  assetUrl: string,
  imageParamsMap: ImageCroppingInfo,
): { srcset: string; media: string; 'data-mq': MediaQuery }[] {
  return (
    Object.entries(imageParamsMap)
      .map(([mq, params]) => ({
        srcset: getOptimizedAssetUrl(assetUrl, params),
        media: `(min-width: ${wbBreakpoints[mq as MediaQuery]}px)`,
        'data-mq': mq as MediaQuery,
      }))
      // we need to reverse the MQ list, since the browser will go from
      // top to bottom and will use the img src for the first matching query.
      // Since e.g. 1920px also satisfies (min-width: 320px) it will always choose the MQ1 url.
      .reverse()
  );
}

/**
 * Creates an array of BBD image URLs for a given base URL and responsive width.
 */
export function mkBBDImgUrls(baseUrl: string, responsiveWidth: number): string[] {
  return Array.from({ length: 36 }, (_, i) => mkBBDImgUrl(baseUrl, i * 10, responsiveWidth));
}

export function mkBBDImgUrl(baseUrl: string, perspective: number, responsiveWidth = 1920): string {
  // ensure we fetch webp images from BBD CE
  const webpBaseUrl = baseUrl.replace('IMGT=P27', 'IMGT=W27').replace('iris.png', 'iris.webp');

  const url = `${webpBaseUrl}&POV=BE${`${perspective}`.padStart(3, '0')}&uni=m`;
  // if the responsive width is 1920, we don't need to resize the images
  return responsiveWidth == 1920 ? url : applyAkamaiIM(url, [['Resize', responsiveWidth]]);
}

export const patternResizeInfo: Record<MediaQuery, ImageParams> = {
  mq1: {
    displayWidth: 480,
  },
  mq2: {
    displayWidth: 768,
  },
  mq3: {
    displayWidth: 1024,
  },
  mq4: {
    displayWidth: 1280,
  },
  mq5: {
    displayWidth: 1440,
  },
  mq6: {
    displayWidth: 1440,
  },
} as const;
