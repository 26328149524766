import { getTranslation } from '@owc/aem-translations-util';
import { getLocale } from '../../utils/locale';
import { i18nProjectId } from '../../lib/configuration';
import type { MessageMap } from './types';

export function loadTranslations(language: string, country: string, tag?: string): Promise<MessageMap> {
  const query: Record<string, unknown> = {
    include_empty_translations: true,
    fallback_locale_id: 'en',
  };
  if (tag) query.tag = tag;
  return getTranslation(getLocale({ language, country }), i18nProjectId, 'i18next', query);
}
