import { ref, computed, watchPostEffect, type Ref } from 'vue';
import type { CTAData } from '@dh-io-globalelem/vehicle-data-store-connection';
import { getVehicleDataStore } from '@dh-io-owpi/shared/src/utils/vehicleDataStore';

import type { StageVehicle } from '@dh-io-owpi/backend-api/src/data-contracts';
import { formatLabel, useTrackingStore } from '@dh-io-owpi/shared/src/stores/tracking';
import { browser } from '@dh-io-owpi/shared/src/environment';

/** Type copied from @dh-io-globalelem/vehicle-data-store-connection */
// url, baseUrl and label keys are optional, even if they are not specified like this at the moment in the original type
interface CTAEntry {
  url?: string;
  baseUrl?: string;
  label?: string;
  type: string;
}

interface MappedCTAEntry extends CTAEntry {
  text: string;
  track: (e: PointerEvent | MouseEvent) => void;
}

export interface MappedCTAs {
  primary?: MappedCTAEntry;
  secondary?: MappedCTAEntry;
  loading: boolean;
}

export function useStageCtasFromCtaService(stageVehicle: Ref<StageVehicle | undefined>): Ref<MappedCTAs> {
  if (!browser) {
    return computed(() => ({ primary: undefined, secondary: undefined, loading: true }));
  }
  const { createTrackLinkClickFn, impression } = useTrackingStore();
  const reactiveCtaState = ref<CTAData>();

  getVehicleDataStore((state) => {
    reactiveCtaState.value = state.cta;
  });

  function _mapCta(kind: 'primary' | 'secondary', cta: CTAEntry | undefined): MappedCTAEntry | undefined {
    return !cta || !cta.url || !cta.label
      ? undefined
      : ({
          ...cta,
          text: cta.label,
          track: createTrackLinkClickFn({ label: `cta.${kind}.${formatLabel(cta.type)}`, action: 'dynamic_stage' }),
        } as const);
  }

  // We will need to revisit this logic once we have a better understanding how data analytics team want to track primary & secondary CTAs
  // when both are present but they have different types. (ex: enquiry, informed)
  const stopWatch = watchPostEffect(() => {
    if (reactiveCtaState.value && stageVehicle.value) {
      const ctas = reactiveCtaState.value;

      const tracked: string[] = [];
      if (ctas.primary) tracked.push(`primary.${formatLabel(ctas.primary.type)}`);
      if (ctas.secondary) tracked.push(`secondary.${formatLabel(ctas.secondary.type)}`);

      if (tracked.length) {
        impression({ label: `cta.${tracked}`, action: 'dynamic_stage' });
        stopWatch();
      }
    }
  });

  return computed(() => {
    if (!reactiveCtaState.value) return { loading: true };
    const ctas = reactiveCtaState.value;

    return {
      primary: _mapCta('primary', ctas.primary),
      secondary: _mapCta('secondary', ctas.secondary),
      loading: false,
    };
  });
}
