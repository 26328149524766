import { loadJsonLd, type VehicleJsonLdData } from '@dh-io-owpi/shared-dynamic-stage/src/service/jsonLd';
import { loadTranslations } from '@dh-io-owpi/shared/src/plugins/i18n/api';
import { createErrorManager, type ErrorManager } from '@dh-io-owpi/shared/src/components/ErrorList/manager';
import { getVehicle } from '@dh-io-owpi/backend-api/src/Stage';
import type { StageVehicle } from '@dh-io-owpi/backend-api/src/data-contracts';
import type { MessageMap } from '@dh-io-owpi/shared/src/plugins/i18n/types';
import type { ValidationNotification } from '@dh-io-owpi/shared/src/components/ErrorList/ValidationNotification';
import type { JsonLdLoaderData, JsonLdLoaderDataOutput } from '@dh-io-owpi/shared/src/ssr/jsonLdHtmlRender';
import type { AemData } from '../src/lib/types/aem';
import { transactionPriceOptions } from '../src/composables/usePrice';

// Used to filter the translations in Phrase, downloading only a subset of the translations necessary for the component.
export const componentPhraseTag = 'dynamic-stage-mvp';

export interface LoaderData extends JsonLdLoaderData<VehicleJsonLdData> {
  fetchedAt: string;
  aemData: AemData;
  messages: MessageMap;
  errors: ValidationNotification[];
  stageVehicle: StageVehicle;
}

export type RenderData = JsonLdLoaderDataOutput<LoaderData>;

/**
 * isomorphic function to load data for the dynamic stage
 * this function is used in the SSR and in the CSR (fallback mode)
 */
export async function loadData(aemData: AemData): Promise<LoaderData> {
  const errorManager = createErrorManager(aemData);
  const isCSR = aemData.renderMode === 'csr';

  const [stageVehicle, jsonLd, messages] = await Promise.all([
    loadVehicle(aemData, errorManager),
    // no need to load the json+ld in CSR fallback mode
    isCSR ? undefined : loadJsonLd(aemData),
    loadTranslations(aemData.language, aemData.country, componentPhraseTag),
  ]);

  // Add a timestamp to the data to be able to check if the data is fresh or not
  const fetchedAt = new Date().toJSON();

  if (!aemData.priceOption) {
    aemData.priceOption = 'listPriceBaseConfig';
  }

  // remove price & currency from the json+ld offers if one of the transaction price options is selected or showPurchasePrice isn't enabled
  if ((transactionPriceOptions.includes(aemData.priceOption) || !aemData.showPurchasePrice) && jsonLd?.offers) {
    // @ts-ignore - price is mandatory in the Offer interface
    delete jsonLd.offers.price;
    // @ts-ignore - priceCurrency is mandatory in the Offer interface
    delete jsonLd.offers.priceCurrency;
  }

  // update the price in the json+ld if the listPriceStageConfig is selected because the price that is sent from BE is always the minimum price
  if (
    aemData.priceOption === 'listPriceStageConfig' &&
    jsonLd?.offers?.price &&
    stageVehicle.priceInformation.configurationPrice
  ) {
    jsonLd.offers.price = stageVehicle.priceInformation.configurationPrice;
  }

  return { stageVehicle, fetchedAt, aemData, jsonLd, messages, errors: errorManager.errors };
}

/**
 * Load the vehicle from the API using the AEM data
 */
export function loadVehicle(aemData: AemData, errorManager?: ErrorManager) {
  return getVehicle({
    country: aemData.country,
    language: aemData.language,
    modelSeries: aemData.modelSeries,
    'sub-brands': [aemData.subBrand],
    'aem-paint-id': aemData.vehicleColorCode,
    'aem-equipment-ids': aemData.featureElements?.map((featureElement) => featureElement.code) as any,
    'author-mode': aemData.isAemEditMode,
  }).then(({ data }) => {
    errorManager?.addValidations(data.errors);
    return data;
  }, errorManager?.sendError);
}
