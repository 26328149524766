<script lang="ts" setup>
import { ref, toRefs } from 'vue';
import { useStageBackground } from '@dh-io-owpi/shared-dynamic-stage/src/composables/useStageBackground';
import type { BrandId } from '@dh-io-owpi/backend-api/src/data-contracts';
import { useAemData } from '@dh-io-owpi/shared/src/plugins/aemData';

const props = withDefaults(
  defineProps<{
    subBrand: BrandId;
    campaignBg: string | null;
  }>(),
  { subBrand: 'MERCEDES_BENZ', campaignBg: null },
);

const { subBrand, campaignBg } = toRefs(props);

const aemData = useAemData();

const isReady = ref(aemData.renderMode !== 'csr');

const srcSets = useStageBackground(subBrand, campaignBg, true);
</script>
<template>
  <Transition name="crossfade">
    <div v-show="isReady" class="owpi-stage-bg">
      <picture>
        <source v-for="item in srcSets.backplate" :key="item.media" v-bind="item" />
        <img
          :src="srcSets.backplate.at(-1)!.srcset"
          class="owpi-stage-bg__backplate"
          alt="Brand specific stage background"
          @load="isReady = true"
        />
      </picture>
      <picture v-if="!campaignBg">
        <source v-for="item in srcSets.pattern" :key="item.media" v-bind="item" />
        <img
          :src="srcSets.pattern!.at(-1)!.srcset"
          class="owpi-stage-bg__pattern"
          alt="Brand specific background pattern"
        />
      </picture>
    </div>
  </Transition>
</template>
<style lang="scss" scoped>
.owpi-stage-bg {
  background: var(--wb-white);
  user-select: none;

  --light: 100%;
  [class*='--dark'] & {
    --light: 0%;
    background: var(--wb-black);
  }
  ::after {
    content: '';
    position: absolute;
    inset: auto 0 0 0;
    height: 210px;

    background-image: linear-gradient(
      180deg,
      hsla(0deg, 0%, var(--light), 0%) 0%,
      hsla(0deg, 0%, var(--light), 9%) 19%,
      hsla(0deg, 0%, var(--light), 18%) 27%,
      hsla(0deg, 0%, var(--light), 27%) 34%,
      hsla(0deg, 0%, var(--light), 36%) 41%,
      hsla(0deg, 0%, var(--light), 45%) 47%,
      hsla(0deg, 0%, var(--light), 55%) 53%,
      hsla(0deg, 0%, var(--light), 64%) 59%,
      hsla(0deg, 0%, var(--light), 73%) 66%,
      hsla(0deg, 0%, var(--light), 82%) 73%,
      hsla(0deg, 0%, var(--light), 91%) 81%,
      hsla(0deg, 0%, var(--light), 100%) 100%
    );
  }

  // All layers fill the whole stage
  &,
  &__pattern,
  &__backplate {
    position: absolute;
    inset: 0;
  }

  &__backplate {
    $max-size: 2550px;
    width: 100%;
    max-width: $max-size;
    height: 100%;
    object-fit: cover;
    object-position: top;
    margin: 0 auto;

    @media (min-width: $max-size) {
      mask: linear-gradient(
          to right,
          transparent 0%,
          rgba(0, 0, 0, 0.9) 4%,
          black 5%,
          black 95%,
          rgba(0, 0, 0, 0.9) 96%,
          transparent 100%
        )
        center;
      mask-size: $max-size 100%;
    }
  }

  &__pattern {
    width: 100%;
    height: 100%;
    object-fit: contain;
    margin: 0 auto;
    --fade-offset: 44%;
    translate: 0 -114px;

    mask: linear-gradient(
        180deg,
        rgb(0, 0, 0) 0%,
        rgb(0, 0, 0) var(--fade-offset),
        transparent calc(var(--fade-offset) + 10%)
      )
      center no-repeat;

    @include breakpoint-from(mq2) {
      --fade-offset: 42%;
    }

    @include breakpoint-from(mq3) {
      width: 80%;
      translate: 0 -2vw;
    }

    @include breakpoint-from(mq4) {
      --fade-offset: 45%;
      translate: 0 -4vw;
    }

    @media (min-width: 1920px) {
      --fade-offset: 48%;
      translate: 0 -3vw;
    }
  }
}

.crossfade {
  &-enter-active,
  &-leave-active {
    transition: opacity 0.5s ease;
  }

  &-enter-from,
  &-leave-to {
    opacity: 0;
  }
}
</style>
