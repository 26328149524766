import { unref, watchPostEffect } from 'vue';
import { version } from '@dh-io-owpi/shared/src/environment';
import { defineStore } from '../store';
import { panic } from '../../utils/panic';

import type { MaybeVehicleDataContext, TrackingAppId, TrackingCtx, TrackingEventDetail } from './types';
import { trackEvent } from './browser';

export type { VehicleDataContext, BrandIdLowerCase } from './types';

export const useTrackingStore = defineStore<TrackingCtx>('pi-tracking', (): TrackingCtx => {
  let appId: TrackingAppId = 'aem';
  let defaultData: MaybeVehicleDataContext = undefined;

  function _track(eventDetail: TrackingEventDetail) {
    const vehicle = unref(defaultData);
    // add default vehicle data to eventDetail if not provided and defaultData is set
    if (!eventDetail.vehicle && vehicle) eventDetail.vehicle = vehicle;
    trackEvent({ application: { id: appId, version }, event: 'click', eventDetail });
  }

  return {
    setAppId(id) {
      appId = id;
    },
    setVehicleData(data) {
      defaultData = data;
    },
    track({ timeout, ...opt }) {
      timeout ? setTimeout(_track, timeout, opt) : _track(opt);
    },
    pageview(page) {
      trackEvent({ application: { id: appId, version }, event: 'pageview', page });
    },
    impression(options) {
      const unwatch = watchPostEffect(() => {
        const vehicle = options.vehicle ?? unref(defaultData);
        if (vehicle) {
          _track({ ...options, category: 'impression', vehicle });
          unwatch();
        }
      });
    },
    systemError(options) {
      _track({ ...options, category: 'impression', name: 'system_error' });
    },
    createTrackOnceFn(...a) {
      let tracked = false;
      return () => {
        if (tracked) return;
        tracked = true;
        return _track(...a);
      };
    },
    createTrackLinkClickFn(options) {
      return (e) => {
        const el = e.currentTarget ?? e.target;
        if (!(el instanceof HTMLAnchorElement)) return;

        // open link in new tab when CTRL key (windows) or Command key (Mac) is pressed
        // or when link is clicked with middle mouse btn.
        const windowTarget = e.ctrlKey || e.metaKey || e.button === 1 ? '_blank' : el.target || '_self';

        // get event target before preventDefault, because it's lost after it.
        const URL = el.href;

        const eventDetail: TrackingEventDetail = {
          ...options,
          category: 'link',
          // get tracking action from data attribute or from parameter
          action: el.dataset.trackingAction ?? options.action ?? panic('No tracking action provided'),
          URL,
        };

        // prevent default behavior of anchor click, so that we can wait for the async tracking before leaving the page.
        e.preventDefault();

        _track(eventDetail);

        window.open(URL, windowTarget);
      };
    },
  };
});

/**
 * @description Method used to format a tracking string to be compliant with tracking specs
 * @param {string} label
 * @returns {string} tracking string formatted or an empty string
 */
export function formatLabel(label?: string): string {
  return (
    label
      ?.toLowerCase()
      // remove special characters, but keep spaces and commas
      .replace(/[^\p{L}\w, -]/gu, '')
      // replace spaces and commas with underscores
      .replace(/[\s,]+/g, '_') ?? ''
  );
}
