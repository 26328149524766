import { type AemData } from '../../plugins/aemData';
import { sendApiError } from '../../services/Error';
import type { ValidationNotification } from './ValidationNotification';
import type { ValidationError } from '@dh-io-owpi/backend-api/src/data-contracts';

export function createErrorManager(aemData: AemData) {
  let validationErrors: ValidationNotification[] = [];

  // add errors to the validationErrors array, discarding repeated errors
  const validationErrorCodes = new Set<string>();
  function addValidations(errors: ValidationError[] | null | undefined) {
    if (!errors) return;
    validationErrors = validationErrors.concat(
      errors.flatMap(({ key, aemAuthorMessage, severity }) => {
        const message = `${key}: ${aemAuthorMessage}`;
        if (validationErrorCodes.has(message)) return [];
        validationErrorCodes.add(message);
        return [{ mode: severity == 'ERROR' ? 'error' : 'warning', message }];
      }),
    );
  }

  function sendError(e: any): never {
    throw sendApiError(aemData, e);
  }

  return {
    get errors() {
      return validationErrors;
    },
    addValidations,
    sendError,
  };
}

export type ErrorManager = ReturnType<typeof createErrorManager>;
