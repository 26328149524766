/* v8 ignore start - still need to think about a testing strategy for this */
import { createSSRApp, createApp, defineAsyncComponent } from 'vue';
import type { AppConfig } from '$app/config';
import { createAemDataPlugin } from '@dh-io-owpi/shared/src/plugins/aemData';
import { mapSSRContextToAemData } from '@dh-io-owpi/shared/src/plugins/aemData/utils';
import { getApiBaseUrl } from '@dh-io-owpi/shared/src/lib/configuration';
import { initializeApi } from '@dh-io-owpi/backend-api';
import { createI18n } from '@dh-io-owpi/shared/src/plugins/i18n';
import { dataDogErrorHandlerPlugin } from '@dh-io-owpi/shared/src/services/Error';
import { adaptJsonLd } from '@dh-io-owpi/shared-dynamic-stage/src/service/jsonLd';
import { renderError } from '@dh-io-owpi/shared/src/ssr/renderFallbackError';
import { PILoaderError } from '@dh-io-owpi/shared/src/services/Error/errors';
import type { ComponentData } from './lib/types/aem';
import { type RenderData, loadData } from '../ssr/loadData';
import App from './App.vue';

export default {
  createApp(renderMode, _componentId, data, error) {
    if (!data || error)
      return renderError(
        error!,
        defineAsyncComponent(() => import('./AppError.vue')),
      );

    // We are sending the aemData within the window.ssrData object, to avoid having to call owc connections or aem-utils+OneContext
    const aemData = data.aemData;
    // renderMode will come as 'ssr' from the ssrData object, but we need to set it to the actual renderMode being passed to the createApp function
    aemData.renderMode = renderMode;
    return (
      // use createApp in csr fallback mode to avoid warnings in Vue, it will still work, but it would try to hydrate the app and then fallback when it founds no DOM
      (renderMode == 'csr' ? createApp : createSSRApp)(App, { data })
        // any additional preparation logic you may require
        .use(createAemDataPlugin(aemData))
        .use(createI18n({ messages: data?.messages }))
        .use(dataDogErrorHandlerPlugin(aemData))
    );
  },
  async loader(context, isSSR) {
    const aemData = mapSSRContextToAemData(context, isSSR);
    try {
      initializeApi(getApiBaseUrl(aemData), true);

      const data = await loadData(aemData);

      adaptJsonLd(data, context);

      return data;
    } catch (error) {
      throw new PILoaderError(error, aemData);
    }
  },
} satisfies AppConfig<ComponentData, RenderData, PILoaderError>;
