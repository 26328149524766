import type { SSRContext, RenderMode } from '@seamless/oneweb-ssr';
import { type AemData } from '../../plugins/aemData';
import { type BrandId } from '@dh-io-owpi/backend-api/src/data-contracts';
import { browser } from '../../environment';

export function logCtxFromSSRContext(ssrContext: SSRContext<unknown>): LogCtx {
  return {
    renderMode: 'ssr',
    market: ssrContext.pageAEMContext.country as string,
    language: ssrContext.pageAEMContext.language as string,
    modelSeries: ssrContext.vehicleContext!.modelSeries as string,
    // @ts-ignore
    subBrand: ssrContext.vehicleContext?.subBrands?.[0] ?? 'MERCEDES_BENZ',
    view: getViewFromUrl(ssrContext.requestUrl),
  };
}

export function logCtxFromAemData({ renderMode, country, language, modelSeries, subBrand, url }: AemData): LogCtx {
  const logCtx: LogCtx = { renderMode, market: country, language, modelSeries, subBrand };
  // the view data is already added by default by Datadog logger in the client
  if (!browser) logCtx.view = getViewFromUrl(url);

  return logCtx;
}

function getViewFromUrl(requestUrl: string) {
  const url = new URL(requestUrl);
  return {
    url: requestUrl,
    url_details: {
      scheme: url.protocol,
      host: url.host,
      path: url.pathname,
      queryString: Object.fromEntries(url.searchParams),
    },
  };
}

export interface LogCtx {
  renderMode: RenderMode;
  market: string;
  language: string;
  modelSeries: string;
  subBrand: BrandId;
  // mimics the view object from the datadog logger that is added by default in the client
  view?: {
    url: string;
    url_details: {
      scheme: string;
      host: string;
      path: string;
      queryString: object;
    };
  };
}
