<script lang="ts" setup>
import { useAemData } from '@dh-io-owpi/shared/src/plugins/aemData';
import { setVehicleConfigurationId } from '@dh-io-owpi/shared/src/utils/vehicleDataStore';
import { initializeApi } from '@dh-io-owpi/backend-api';
import { getApiBaseUrl } from '@dh-io-owpi/shared/src/lib/configuration';
import { useTrackingStore } from '@dh-io-owpi/shared/src/stores/tracking';

import type { ComponentData } from './lib/types/aem';
import type { RenderData } from '../ssr/loadData';
import type { BrandIdLowerCase } from '@dh-io-owpi/shared/src/stores/tracking/types';
import DynamicStageMvp from './DynamicStageMvp.vue';

const props = defineProps<{ data?: RenderData }>();

const aemData = useAemData<ComponentData>();

if (!import.meta.env.SSR) {
  useTrackingStore().setVehicleData({ subBrand: aemData.subBrand.toLowerCase() as BrandIdLowerCase });
  initializeApi(getApiBaseUrl(aemData));
  const vehicleId = props.data?.stageVehicle.vehicleId;

  if (vehicleId) {
    setVehicleConfigurationId(vehicleId);
  }
}
</script>

<template>
  <DynamicStageMvp :stageVehicle="data?.stageVehicle" :errors="data?.errors ?? []" />
</template>

<style lang="scss">
// avoid CLS by setting the min-height to the stage, only if the stage is not rendered yet
// we should optimize the height calculation in the future
div[data-component-name='owpi-dynamic-stage-mvp']:not(:has(.owpi-dynamic-stage-mvp)) {
  $car360-ratio-height: calc(100vw * 9 / 16);
  @include breakpoint-to(mq2) {
    min-height: calc($car360-ratio-height - 1vw + 227px);
  }
  @include breakpoint-between(mq2, mq3) {
    min-height: calc($car360-ratio-height - 1vw + 157px);
  }
  @include breakpoint-between(mq3, mq5) {
    min-height: 49vw;
  }
  @include breakpoint-between(mq5, mq6) {
    min-height: calc(47vw + 2px);
  }
  @include breakpoint-from(mq6) {
    min-height: 42vw;
  }
  @media (min-width: 1920px) {
    min-height: 823px;
  }
}
</style>
