import type { TrackingEvent } from './types';

// https://git.i.mercedes-benz.com/dh-io-seamless/owc-fe-libs/blob/develop/packages/connection-aem-tracking/src/connections/aem-tracking/owc-tracking-manager-connection.ts#L40
export function trackEvent(event: TrackingEvent): void {
  if (import.meta.env.SSR) {
    // eslint-disable-next-line no-console
    console.warn('Tracking event not sent because it is not running in a browser', event);
    return;
  }

  if (!window.dataLayer_ow) {
    // eslint-disable-next-line no-console
    console.warn('Tracking event not sent because dataLayer_ow is not defined', event);
    return;
  }

  window.dataLayer_ow.push(event);
}
