import { applyAkamaiIM } from './getAkamaiIM';
import { wbBreakpoints, wbBreakpointList } from '../composables/useMediaQuery';

const BBD_IMG_SIZE = 1920;

export function mkAkamaiResponsiveSrcset(src: string, maxImageSize = BBD_IMG_SIZE): string {
  return wbBreakpointList
    .map((mq) => {
      const s = wbBreakpoints[mq];
      return `${applyAkamaiIM(src, [['Resize', s]])} ${s}w`;
    })
    .concat(`${src} ${maxImageSize}w`)
    .join(', ');
}
