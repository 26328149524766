import { reactive, computed, type ComputedRef, ref, onMounted } from 'vue';
import { useAemData } from '@dh-io-owpi/shared/src/plugins/aemData';
import type { ComponentData } from '../lib/types/aem';
import type { PlsUiEventData } from '@dh-io-pls/pls-ui-types';

import { formatPrice } from '@dh-io-owpi/shared-dynamic-stage/src/utils/formatPrice';
import { getVehiclePrice } from '@dh-io-owpi/backend-api/src/Country';
import type { PlsInformation, PlsInformationObject } from '@dh-io-owpi/backend-api/src/data-contracts';

type PriceState = {
  listPrice?: string;
  listPriceLoading: boolean;
  showPrice: boolean;
  plsData?: PlsInformationObject;
  onTransactionPriceChange?: (evt: PlsUiEventData) => void;
  authorErrorMessage?: string;
  pricePrefix: ComputedRef<string | undefined>;
};

// Contants for the price options
const LIST_PRICE_BASE_CONFIG = 'listPriceBaseConfig';
const LIST_PRICE_STAGE_CONFIG = 'listPriceStageConfig';
const TRANSACTION_PRICE_BASE_CONFIG = 'transactionPriceBaseConfig';
const TRANSACTION_PRICE_STAGE_CONFIG = 'transactionPriceStageConfig';

const PLS_STATUS_SUCCESS = 'success';

const listPriceOptions = [LIST_PRICE_BASE_CONFIG, LIST_PRICE_STAGE_CONFIG];
export const transactionPriceOptions = [TRANSACTION_PRICE_BASE_CONFIG, TRANSACTION_PRICE_STAGE_CONFIG];
export const AUTHOR_ERROR_MESSAGE = 'Transaction prices are unavailable. List price is currently being shown';
const FALLBACK_PRICE_OPTION = LIST_PRICE_BASE_CONFIG;
// Map the price options to the prefix translation for the price label
const pricePrefixMap = {
  [LIST_PRICE_BASE_CONFIG]: 'dynamicStage.startingPricePrefix',
  [LIST_PRICE_STAGE_CONFIG]: 'dynamicStage.configurationPricePrefix',
  [TRANSACTION_PRICE_BASE_CONFIG]: 'dynamicStage.startingPricePrefix',
  [TRANSACTION_PRICE_STAGE_CONFIG]: 'dynamicStage.configurationPricePrefix',
};
export interface PriceOptions {
  stageVehicleId?: string;
  plsInformation?: PlsInformation;
}

/**
 * Composable to handle price data, it will handle list price and transaction price for PLS UI component
 *
 * @param priceOptions - PriceOptions object with the stageVehicleId and plsInformation
 */
export function usePrice({ stageVehicleId, plsInformation }: PriceOptions) {
  const aemData = useAemData<ComponentData>();
  const optionPriceSelected = ref(aemData.priceOption);
  const state = reactive<PriceState>({
    listPrice: undefined, // List price formatted with currency
    showPrice: aemData.showPurchasePrice,
    // if author selected any list price option show a loading state while we fetch price from BE
    listPriceLoading: aemData.showPurchasePrice && listPriceOptions.includes(aemData.priceOption),
    plsData: undefined,
    authorErrorMessage: undefined,
    pricePrefix: computed(() => (aemData.showPurchasePrice ? pricePrefixMap[optionPriceSelected.value] : undefined)),
  });

  // To avoid hydration mismatch, we run this logic only on the client side after the component is mounted
  onMounted(() => {
    if (!state.showPrice || !stageVehicleId) return;
    _handlePriceOptions();
  });

  function _handlePriceOptions() {
    if (listPriceOptions.includes(optionPriceSelected.value)) {
      _getListPrice();
    }
    // If we have pls data and one of the transactionPriceOptions is selected
    // we will show the transaction price based on the option selected by the author
    // otherwise we fallback to the initial list price configuration
    else if (transactionPriceOptions.includes(optionPriceSelected.value) && plsInformation) {
      _handleTransactionPriceOptions();
    } else {
      _fallbackToListPriceBaseConfig();
    }
  }

  function _handleTransactionPriceOptions() {
    state.plsData =
      optionPriceSelected.value === TRANSACTION_PRICE_BASE_CONFIG
        ? plsInformation?.initialConfiguration
        : plsInformation?.currentConfiguration;

    // Register this event listener only if the author has selected transaction price options
    state.onTransactionPriceChange = (evt: PlsUiEventData) => {
      if (evt.detail[0].plsStatus !== PLS_STATUS_SUCCESS) _fallbackToListPriceBaseConfig();
    };
  }

  // Fallback to listPriceBaseConfig if transaction price is not available
  function _fallbackToListPriceBaseConfig() {
    state.listPriceLoading = true;
    optionPriceSelected.value = FALLBACK_PRICE_OPTION;
    state.authorErrorMessage = AUTHOR_ERROR_MESSAGE;
    _getListPrice();
  }

  async function _getListPrice() {
    if (!stageVehicleId) return;

    try {
      const { data } = await getVehiclePrice(
        aemData.country,
        aemData.language,
        aemData.modelSeries,
        encodeURIComponent(stageVehicleId),
      );

      if (!data.currency) return;
      let price;

      if (optionPriceSelected.value === LIST_PRICE_BASE_CONFIG && data.startingPrice) price = data.startingPrice;
      else if (optionPriceSelected.value === LIST_PRICE_STAGE_CONFIG && data.configurationPrice)
        price = data.configurationPrice;
      if (price) state.listPrice = formatPrice(aemData, { price, currency: data.currency });
    } catch (err) {
      state.showPrice = false;
    } finally {
      state.listPriceLoading = false;
    }
  }

  return state;
}
