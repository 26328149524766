<script lang="ts" setup>
import { computed, toRef, watch } from 'vue';
import { vAnchor } from '@dh-io-owpi/shared/src/directives/anchor.directive';
import { vAemReady } from '@dh-io-owpi/shared/src/directives/aem-ready.directive';
import { useAemData } from '@dh-io-owpi/shared/src/plugins/aemData';
import { useStageCtasFromCtaService } from '@dh-io-owpi/shared-dynamic-stage/src/composables/useStageCtasFromCtaService';
import { usePurchasePriceTooltip } from '@dh-io-owpi/shared-dynamic-stage/src/composables/usePurchasePriceTooltip';
import { usePrice } from './composables/usePrice';
import type { ValidationNotification } from '@dh-io-owpi/shared/src/components/ErrorList/ValidationNotification';
import type { StageVehicle } from '@dh-io-owpi/backend-api/src/data-contracts';

import type { ComponentData } from './lib/types/aem';
import StageBg from './components/StageBg/StageBg.vue';
import ThreesixtyViewer from './components/ThreesixtyViewer/ThreesixtyViewer.vue';
import StagePrice from './components/StagePrice/StagePrice.vue';
import PlsUiWrapper from './components/PlsUiWrapper/PlsUiWrapper.vue';
import { STAGE_ERROR_MESSAGE } from '@dh-io-owpi/shared-dynamic-stage/src/utils/constants';
import { ErrorList } from '@dh-io-owpi/shared/src/components/ErrorList';

const props = defineProps<{
  errors: (ValidationNotification | string)[];
  stageVehicle?: StageVehicle;
}>();
const aemData = useAemData<ComponentData>();

const purchasePriceTooltip = usePurchasePriceTooltip(aemData.purchasePriceTooltip);
const errors = toRef(props, 'errors');
const hasErrors = computed(() => errors.value.length > 0);
const isDark = aemData.subBrand === 'MERCEDES_AMG';

const stageVehicle = toRef(props, 'stageVehicle');

const ctas = useStageCtasFromCtaService(stageVehicle);

const price = usePrice({
  stageVehicleId: stageVehicle.value?.vehicleId,
  plsInformation: stageVehicle.value?.plsInformation ?? undefined,
});

watch(
  () => price.authorErrorMessage,
  (msg) => {
    if (msg) {
      errors.value = [...errors.value, msg];
    }
  },
);
</script>

<template>
  <div
    v-anchor="'stage'"
    v-aem-ready="aemData"
    dir="ltr"
    :class="['owpi-dynamic-stage-mvp', { 'owpi-dynamic-stage-mvp--dark': isDark }]"
  >
    <StageBg :subBrand="aemData.subBrand" :campaignBg="stageVehicle?.backgroundImageUrl" />
    <ErrorList v-if="aemData.isAemEditMode && hasErrors" class="owpi-dynamic-stage-mvp__error-list" :errors="errors" />
    <div v-if="!aemData.isAemEditMode && !stageVehicle && hasErrors" class="owpi-dynamic-stage-mvp__error wb-type-copy">
      {{ $t('dynamicStage.errorMessage') ?? STAGE_ERROR_MESSAGE }}
    </div>

    <div class="owpi-dynamic-stage-mvp__content">
      <ThreesixtyViewer
        class="owpi-dynamic-stage-mvp__360-viewer"
        :stageVehicle="stageVehicle"
        :darkMode="isDark"
        :hasErrors="hasErrors"
      />
      <div class="owpi-dynamic-stage-mvp__head">
        <h1 v-if="stageVehicle" class="wb-type-heading-l">
          {{ stageVehicle.modelName }}
        </h1>
        <wb-skeleton v-else-if="!stageVehicle && !hasErrors" class="wb-type-heading-l owpi-dynamic-stage-mvp__title_sk"
          >&nbsp;</wb-skeleton
        >

        <div class="owpi-dynamic-stage-mvp__prices">
          <template v-if="price.showPrice">
            <StagePrice
              v-if="price.listPrice || price.listPriceLoading"
              :price="price.listPrice"
              :pricePrefix="price.pricePrefix"
              :purchasePriceTooltip="purchasePriceTooltip"
              :isPriceLoading="price.listPriceLoading"
              :isDark="isDark"
            />
            <PlsUiWrapper
              v-else-if="price.plsData"
              :isDark="isDark"
              :plsData="price.plsData"
              :pricePrefix="price.pricePrefix"
              :purchasePriceTooltip="purchasePriceTooltip"
              :onTransactionPriceChange="price.onTransactionPriceChange"
            />
          </template>
        </div>
      </div>

      <div class="owpi-dynamic-stage-mvp__options">
        <div v-if="ctas" class="owpi-dynamic-stage-mvp__ctas">
          <a
            v-if="ctas.secondary"
            :data-type="ctas.secondary.type"
            :href="ctas.secondary.url"
            :title="ctas.secondary.text"
            class="wb-button wb-button--secondary wb-button--semi-transparent wb-button--medium"
            :class="{ 'wb-button--theme-dark': isDark }"
            target="_self"
            @click="(e: PointerEvent | MouseEvent) => ctas?.secondary?.track(e)"
            @auxclick="(e: PointerEvent | MouseEvent) => ctas?.secondary?.track(e)"
          >
            {{ ctas.secondary.text }}
          </a>
          <wb-skeleton v-else-if="ctas.loading" class="wb-button wb-button--medium owpi-dynamic-stage-mvp__cta_sk"
            >&nbsp;</wb-skeleton
          >
          <a
            v-if="ctas.primary"
            :data-type="ctas.primary.type"
            :href="ctas.primary.url"
            :title="ctas.primary.text"
            class="wb-button wb-button--primary wb-button--medium"
            :class="{ 'wb-button--theme-dark': isDark }"
            target="_self"
            @click="(e: PointerEvent | MouseEvent) => ctas?.primary?.track(e)"
            @auxclick="(e: PointerEvent | MouseEvent) => ctas?.primary?.track(e)"
          >
            {{ ctas.primary.text }}
          </a>
          <wb-skeleton v-if="ctas.loading" class="wb-button wb-button--medium owpi-dynamic-stage-mvp__cta_sk"
            >&nbsp;</wb-skeleton
          >
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.owpi-dynamic-stage-mvp {
  position: relative;
  overflow: hidden;
  $root: &;

  &__title_sk {
    width: 20%;
  }

  &__cta_sk {
    min-width: 180px;
  }

  &__content {
    position: relative;
    @include wb-content-width;
  }

  &__notification-wrapper {
    text-align: start;
    @include wb-content-width;
    @include wb-spacing('padding-top', 'xl');

    &:only-child {
      @include wb-spacing('padding-bottom', 'xl');
    }
  }

  &__error-list {
    &:not(:first-child) {
      @include wb-spacing('margin-top', 'xs');
    }
  }

  &__head {
    text-align: center;
    color: var(--wb-grey-20);
    z-index: 1;

    #{$root}--dark & {
      color: var(--wb-white);
    }

    wb-skeleton {
      display: inline-block;
    }

    h1 {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;

      @include breakpoint-from(mq3) {
        -webkit-line-clamp: 1;
      }
    }
  }

  &__prices {
    margin: var(--wb-spacing-xxs) 0 var(--wb-spacing-s);
    display: grid;
    gap: 4px;
    justify-items: center;
    height: 1.75rem;
  }

  &__options {
    padding-bottom: var(--wb-spacing-xs);
    // elevate CTAs above 360 viewer overlay
    z-index: 1;

    > .owpi-dynamic-stage-mvp__ctas {
      display: flex;
      justify-content: center;
      gap: var(--wb-spacing-xxs);
    }
  }

  &__error {
    position: absolute;
    top: 10%;
    inset-inline-start: 50%;
    transform: translateX(-50%);
    text-align: center;

    #{$root}--dark & {
      color: var(--wb-white);
    }

    @include breakpoint-from(mq2) {
      top: 30%;
    }

    @include breakpoint-from(mq3) {
      top: 50%;
    }
  }

  &__ctas {
    min-height: 48px;
    @include breakpoint-to(mq1) {
      min-height: 104px;
      flex-direction: column-reverse;
    }

    @include breakpoint-to(mq2) {
      margin-top: var(--wb-spacing-l);
    }
  }

  //// -- content on top of the 360

  @include breakpoint-from(mq3) {
    &__content {
      overflow: hidden;
    }
    &__head {
      margin-top: var(--wb-spacing-xs);

      position: absolute;
      inset: 0 0 auto 0;
    }
    &__options {
      position: absolute;
      inset: auto 0 0 0;
    }
  }

  //// -- 360 viewer size adjusts

  @include breakpoint-between(mq3, mq5) {
    &__360-viewer {
      margin: 6% 10%;
    }
  }

  @include breakpoint-between(mq5, mq6) {
    &__360-viewer {
      // scale down a little bit to make it fit better in the screen
      margin: 5% 10%;
    }
  }
  @include breakpoint-from(mq6) {
    &__360-viewer {
      // scale down a little bit to make it fit better in the screen
      margin: 2% 10%;
    }
  }
}
</style>
