import { isInsideSPA } from '@dh-io-owpi/shared/src/environment';
import { type ImageCroppingInfo, CAMPAIGN_BG_WIDTH } from '.';

// displayWidth is minimum width for next MQ to ensure a sharp image when resizing
const backplateCroppingInfo_V2: ImageCroppingInfo = {
  mq1: {
    y: 387,
    width: 1772,
    displayWidth: 480,
    height: 3086,
  },
  mq2: {
    y: 678,
    width: 1772,
    displayWidth: 768,
    height: 2348,
  },
  mq3: {
    y: 1110,
    width: 1845,
    displayWidth: 1024,
    height: 1612,
  },
  mq4: {
    y: 1319,
    width: 2078,
    displayWidth: 1280,
    height: 1196,
  },
  mq5: {
    y: 1345,
    width: 2034,
    displayWidth: 1440,
    height: 1078,
  },
  mq6: {
    y: 1360,
    width: CAMPAIGN_BG_WIDTH,
    height: 1023,
  },
} as const;

const backplateCroppingInfo_SPA: ImageCroppingInfo = {
  mq1: {
    y: 475,
    width: 1772,
    displayWidth: 480,
    height: 3086,
  },
  mq2: {
    y: 780,
    width: 1772,
    displayWidth: 768,
    height: 2348,
  },
  mq3: {
    y: 1210,
    width: 1845,
    displayWidth: 1024,
    height: 1612,
  },
  mq4: {
    y: 1419,
    width: 2078,
    displayWidth: 1280,
    height: 1196,
  },
  mq5: {
    y: 1445,
    width: 2034,
    displayWidth: 1440,
    height: 1078,
  },
  mq6: {
    y: 1400,
    width: CAMPAIGN_BG_WIDTH,
    height: 1023,
  },
} as const;

export const backplateCroppingInfo = isInsideSPA ? backplateCroppingInfo_SPA : backplateCroppingInfo_V2;
