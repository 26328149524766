// adapted from https://github.com/sveltejs/kit/blob/master/packages/kit/src/runtime/app/environment.js

import { BROWSER, DEV } from 'esm-env';

/**
 * true if the app is running in the browser (client-side)
 * false if the app is running in node (server-side)
 */
export const browser = BROWSER;

/**
 * true if the app is running in development mode
 */
export const dev = DEV;

/**
 * the app version
 */
export const version = import.meta.env?.APP_VERSION || '';

export type AppName = 'owpi-dynamic-stage' | 'owpi-dynamic-stage-mvp' | 'owpi-vehicle-features' | 'owpi-spa';
/**
 * the app name
 */
export const appName = import.meta.env.APP_NAME as AppName;

/**
 * true if the component is being rendered as part of the SPA
 */
export const isInsideSPA = appName === 'owpi-spa';
