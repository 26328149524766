import type { AemData } from '../plugins/aemData';
import { nextTick, type FunctionDirective } from 'vue';

/**
 * Directive to add the class 'owpi-aem-ready' to the host element of the component.
 * If the component has a dialog option `isHiddenOnPageLoad`, the class 'aem--is-hidden' is added as well.
 */
export const vAemReady: FunctionDirective<HTMLElement, AemData> = async (el, binding) => {
  await nextTick();

  const host = ((el.getRootNode() as ShadowRoot | undefined)?.host as HTMLElement | undefined) ?? el;

  if (host && binding.value && binding.value !== binding.oldValue) {
    host.classList.add('owpi-aem-ready');
    host.classList.toggle('aem--is-hidden', !!binding.value.aemTargetingHiddenInitially);
  }
};
