<script lang="ts" setup>
import type { PlsInformationObject } from '@dh-io-owpi/backend-api/src/data-contracts';
import type { PlsUiEventData } from '@dh-io-pls/pls-ui-types';
import { ref, watch } from 'vue';

const props = defineProps<{
  isDark: boolean;
  plsData: PlsInformationObject;
  pricePrefix?: string;
  purchasePriceTooltip?: string;
  onTransactionPriceChange?: (evt: PlsUiEventData) => void;
}>();

const plsUiRef = ref<HTMLElement>();
const plsUiConfRef = ref<HTMLElement>();

watch(
  [props.plsData, plsUiConfRef, plsUiRef],
  ([plsD, configRef, uiRef]) => {
    if (!configRef && !plsD && !uiRef) return;
    // @ts-ignore
    configRef.product = plsD;
    // @ts-ignore
    if (props.isDark) uiRef.setAttribute('dark-mode', '');
  },
  { flush: 'post' },
);
</script>
<template>
  <pls-ui
    ref="plsUiRef"
    :class="['pls-ui', { 'pls-ui--dark': isDark }]"
    loading="lazy"
    customerType="B2C"
    @initialize="onTransactionPriceChange"
  >
    <pls-ui-configuration ref="plsUiConfRef" />
    <div class="pls-ui__wrapper">
      <span v-if="pricePrefix" class="wb-type-label pls-ui__price-prefix">{{ $t(pricePrefix) }}</span>
      <pls-ui-value type="purchase-price" />
      <pls-ui-breakdown>
        <!-- eslint-disable-next-line -->
        <div slot="additionalData">
          {{ purchasePriceTooltip }}
        </div>
      </pls-ui-breakdown>
    </div>
  </pls-ui>
</template>

<style lang="scss" scoped>
.pls-ui {
  min-width: 180px;

  &__wrapper {
    display: inline-flex;
    align-items: center;
  }

  &__wrapper ::part(pls-ui-value) {
    margin-inline-start: var(--wb-spacing-xxs);
  }

  &__price-prefix {
    color: var(--wb-grey-45);
  }

  &--dark .pls-ui__wrapper {
    --pls-ui-text-color: var(--wb-white);
  }

  &--dark .pls-ui__price-prefix {
    color: var(--wb-white);
  }
}
</style>
