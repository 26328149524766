import type { SharedAemData } from '../types/aem';
import { type SSRContext } from '@seamless/oneweb-ssr';
import { getJsonLd } from '@dh-io-owpi/backend-api/src/Seo';
import type { VehicleJsonLd } from '@dh-io-owpi/backend-api/src/data-contracts';
import type { JsonLdLoaderData } from '@dh-io-owpi/shared/src/ssr/jsonLdHtmlRender';
import { browser } from '@dh-io-owpi/shared/src/environment';
import { sendApiError } from '@dh-io-owpi/shared/src/services/Error';

/**
 * Load the json+ld from the API using the AEM data
 */
export function loadJsonLd(aemData: SharedAemData): Promise<VehicleJsonLdData | undefined> {
  return getJsonLd(
    {
      country: aemData.country,
      language: aemData.language,
      modelSeries: aemData.modelSeries,
      'sub-brands': [aemData.subBrand],
      'aem-paint-id': aemData.vehicleColorCode,
    },
    { retries: 0 },
  )
    .then(({ data }) => {
      if (browser) {
        return {
          ...data,
          url: location.origin + location.pathname,
          description: document.querySelector('meta[name="description"]')?.getAttribute('content') || '',
        };
      }
      return data;
    })
    .catch((e) => {
      sendApiError(aemData, e);
      // JSON+LD is not critical, so we don't want to block the page from loading
      return undefined;
    });
}

export interface VehicleJsonLdData extends VehicleJsonLd {
  url?: string;
  description?: string;
  image?: string;
}

/**
 * Add the current URL, description and image to the json+ld
 * We only get this information in the SSR context. Our API does not provide this information.
 */
export function adaptJsonLd(data: JsonLdLoaderData<VehicleJsonLdData>, context: SSRContext<unknown>) {
  if (data.jsonLd) {
    data.jsonLd.url = context.requestUrl;
    data.jsonLd.description = context.pageProperties?.description ?? '';
    data.jsonLd.image = context.pageProperties?.['og:image'] ?? '';
  }
}
