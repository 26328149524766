import { initializeLogger } from '@seamless/logger';
import { type Logger } from './types';

export function createLogger(): Logger {
  // In development, we want to use the browser console for logging, to avoid sending logs to seamless-logger
  if (import.meta.env.DEV && !import.meta.env.TEST) return console;

  const pageEnv = (window as any).aemNamespace?.pageEnvironmentVariables;
  return initializeLogger(import.meta.env.APP_NAME, {
    tenant: 'owpi',
    configs: {
      component: import.meta.env.APP_NAME,
      componentVersion: import.meta.env.APP_VERSION,
      env: pageEnv?.stage?.toLowerCase(),
      market: pageEnv?.country,
      language: pageEnv?.language,
      modelSeries: pageEnv?.vehicleData?.modelSeries,
      subBrand: pageEnv?.vehicleData?.subBrands?.[0] ?? 'MERCEDES_BENZ', // This should be overridden in SPA when switching style
    },
  });
}
