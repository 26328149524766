import { type AemData } from '../../plugins/aemData';
import { logCtxFromAemData } from '../logger/ctx';

/**
 * This error is used to wrap any error that occurs during the `loader` function in the main entry of the oneweb-ssr plugin.
 * It allows us to pass the AemData object to the error handler,
 * that can then be used to render the fallback error page, with the correct AemData set.
 *
 * @param cause The original error that caused the failure.
 * @param aemData The AemData object that was used to render the page.
 */
export class PILoaderError extends Error {
  constructor(
    cause: unknown,
    public readonly aemData: AemData,
  ) {
    super('PILoaderError', { cause });
    this.name = 'PILoaderError';
  }
}

/**
 * This error is used by the "Error" service to wrap any error that we report, like sendApiError, sendServiceError and dataDogErrorHandlerPlugin.
 * It includes the message, the context we built on the _sendError function and the original error that caused the failure.
 *
 * @param message The message that will be logged in datadog.
 * @param ctx The context that will be added to the log in datadog.
 * @param cause The original error that caused the failure
 */
export class PISendError extends Error {
  constructor(
    message: string,
    public readonly ctx: any,
    cause: unknown,
  ) {
    super(message, { cause });
    this.name = 'PISendError';
  }
}

function isPILoaderError(error: unknown): error is PILoaderError {
  return (error as PILoaderError)?.name === 'PILoaderError';
}

function isPISendError(error: unknown): error is PISendError {
  return (error as PISendError)?.name === 'PISendError';
}

/**
 * Map an error that occurred during the rendering of a component to a message and context object that can be logged.
 * This is used to log the error in a structured way, and to send it to datadog.
 */
export function mapComponentRenderingError(error: unknown) {
  let message = 'Fail: Error Rendering component';
  let ctx: any = {};

  // Unwrap the PILoaderError to get the original error and map the AemData to the context
  if (isPILoaderError(error)) {
    ctx = logCtxFromAemData(error.aemData);
    error = error.cause;
  }

  // Get the message and context from the PISendError
  if (isPISendError(error)) {
    message = 'Fail: ' + error.message;
    ctx = error.ctx;
  }

  // If the error is an instance of Error, we can extract the message and stack and enrich the context object with it.
  if (error instanceof Error) {
    if (!ctx.error) ctx.error = {};
    ctx.error.message = error.message;
    ctx.error.stack = error.stack;
  }

  // ensure the errorType is set to RENDER, overwriting any previous value
  ctx.errorType = 'RENDER';

  return { message, ctx };
}
