import type { FunctionDirective } from 'vue';

/**
 * This directive is used to detect when a stencil component is ready.
 * It is used in the Vue components to wait for the stencil component to be hydrated.
 */
export const vStencilReady: FunctionDirective<HTMLElement, (el: HTMLElement) => void> = (el, b) => {
  // if the element is already hydrated, we can call the callback immediately
  // this may happen specially with SSR
  if (el.classList.contains('hydrated')) {
    b.value(el);
    return;
  }

  const observer = new MutationObserver((mutations) => {
    for (const mutation of mutations) {
      if (mutation.target instanceof Element && mutation.target.nodeType === Node.ELEMENT_NODE) {
        if (mutation.target.classList.contains('hydrated')) {
          b.value(el);
          observer.disconnect();
        }
      }
    }
  });

  observer.observe(el, {
    attributes: true,
    attributeFilter: ['class'],
  });
};
