import { type Component, createApp } from 'vue';
import { type AemData, createAemDataPlugin } from '../plugins/aemData';
import { createI18n } from '../plugins/i18n';
import { type PILoaderError, mapComponentRenderingError } from '../services/Error/errors';
import { logger } from '../services/logger';
import { logCtxFromAemData } from '../services/logger/ctx';
import { loadTranslations } from '../plugins/i18n/api';

export async function renderError(error: PILoaderError, component: Component) {
  const { message, ctx } = mapComponentRenderingError(error);
  const aemData = error.aemData ?? ({} as AemData);
  const logCtx = logCtxFromAemData(error.aemData);

  logger.error(message, { ...logCtx, ...ctx });

  const messages = await loadTranslations(aemData.language, aemData.country).catch(() => ({}));

  return createApp(component, { error }).use(createAemDataPlugin(aemData)).use(createI18n({ messages }));
}
