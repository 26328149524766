import type { Plugin } from 'vue';
import type { HttpResponse } from '@dh-io-owpi/backend-api/src/http-client';
import type { Errors } from '@dh-io-owpi/backend-api/src/data-contracts';
import type { AemData } from '../../plugins/aemData';
import { logger } from '../logger';
import { addRUMError } from '../../utils/ddRum';
import { logCtxFromAemData } from '../logger/ctx';
import { PISendError } from './errors';

interface Context {
  [key: string]: unknown;
  errorType: ErrorType;
  dd?: { trace_id?: Errors['traceId'] };
  tenant?: string;
  apiService?: string;
}

function _sendError(aemData: AemData, message: string, ctx: Context, error: any, cause = error) {
  // we may not need RUM anymore as we are already sending errors to datadog via @seamless/logger
  // keep using `service` instead of `apiService` for legacy reasons, all this RUM logic may be removed
  const { apiService, ...r } = ctx;
  addRUMError(error, {
    appName: import.meta.env.APP_NAME,
    appVersion: import.meta.env.APP_VERSION,
    tenant: 'owpi',
    service: apiService,
    ...r,
  });

  const logMessage = `[${ctx.errorType} ERROR][${aemData.country}][${aemData.modelSeries}] ${message}`;
  const logCtx = {
    ...logCtxFromAemData(aemData),
    ...ctx,
    error: error?.message
      ? {
          message: error.message,
          stack: error.stack,
        }
      : error,
  };

  logger.error(logMessage, logCtx);

  return new PISendError(logMessage, logCtx, cause);
}

/**
 * Send an error from out pi-backend to datadog
 */
export function sendApiError<D, E extends Errors & { message?: string }, R extends HttpResponse<D, E>>(
  aemData: AemData,
  error: R,
) {
  const e = error.error ?? error;

  const ctx: Context = { errorType: 'API', apiService: 'pi-backend' };

  let message = '';
  if (e) {
    if (e.traceId) (ctx.dd ??= {}).trace_id = e.traceId;
    if (e.status && e.reason) message = e.status + ': ' + e.reason;
    else if (e.message) message = e.message;
    if (e.errors?.[0]?.message) message += ' - ' + e.errors[0].message;
  }

  return _sendError(aemData, message, ctx, { ...e, url: error.response?.url }, error);
}

/**
 * Send an error from a third party service to datadog (deeplinks, phrase)
 */
export const sendServiceError = (aemData: AemData, error: any, apiService: string, message = '') =>
  _sendError(aemData, message, { errorType: 'API', apiService }, error);

// send all unhandled component errors to datadog
export function dataDogErrorHandlerPlugin(aemData: AemData): Plugin {
  return {
    install(app) {
      // not expecting to already have an error handler, as this should be installed before any other plugins
      app.config.errorHandler = (error, _vm, _info) => _sendError(aemData, '', { errorType: 'CODE' }, error);
    },
  };
}

type ErrorType = 'API' | 'CODE' | 'RENDER';
