import { debounceIdle } from '@dh-io-owpi/shared/src/utils/fn.util';
import { mkAkamaiResponsiveSrcset } from '@dh-io-owpi/shared/src/utils/responsiveImage';
import { ref, computed, type Ref, type ImgHTMLAttributes } from 'vue';
import { wbBreakpoints, currentMediaQuery, wbBreakpointList } from '@dh-io-owpi/shared/src/composables/useMediaQuery';
import { mkBBDImgUrl } from '../utils/bbdImgUrls';

const BBD_IMG_SIZE = 1920;

/**
 * Returns the width of the upper breakpoint
 * When the window is resized up it will change the size, but ignore it if it is smaller
 */
export function useResponsiveImage(maxImageSize = BBD_IMG_SIZE): Ref<number> {
  const prevSize = ref(0);

  const debounced = debounceIdle((v: number) => (prevSize.value = v));

  return computed(() => {
    const mq = currentMediaQuery.value;
    const nextMQ = wbBreakpointList[wbBreakpointList.indexOf(mq) + 1];
    const nextSize = nextMQ ? wbBreakpoints[nextMQ] : maxImageSize;

    // In the first run we always set the prevSize
    if (!prevSize.value) prevSize.value = nextSize;
    // Ignore if the next size is smaller
    else if (nextSize > prevSize.value) debounced(nextSize);

    return prevSize.value;
  });
}

export function mkBBDResponsiveParams(baseImageUrl: string, perspective: number): ImgHTMLAttributes {
  const src = mkBBDImgUrl(baseImageUrl, perspective);
  return {
    src: src,
    srcset: mkAkamaiResponsiveSrcset(src),
  };
}
