<script lang="ts" setup>
import type { Components } from '@workbench/core';
import { ref, watch, reactive } from 'vue';
import {
  useResponsiveImage,
  mkBBDResponsiveParams,
} from '@dh-io-owpi/shared-dynamic-stage/src/composables/useResponsiveImage';
import { STAGE_INITIAL_PERSPECTIVE as initialPerspective } from '@dh-io-owpi/shared-dynamic-stage/src/utils/constants';

import { useTrackingStore } from '@dh-io-owpi/shared/src/stores/tracking';
import type { StageVehicle } from '@dh-io-owpi/backend-api/src/data-contracts';
import { useAemData } from '@dh-io-owpi/shared/src/plugins/aemData';
import { mkBBDImgUrl } from '@dh-io-owpi/shared-dynamic-stage/src/utils/bbdImgUrls';
import { cacheKill, waitForLoad } from '@dh-io-owpi/shared/src/utils/img.util';
import { vStencilReady } from '@dh-io-owpi/shared/src/directives/stencil-ready.directive';

type WB360 = HTMLElement & Components.Wb360Viewer;
type ImageConfig = Components.Wb360Viewer['images'][0];

const aemData = useAemData();

const props = defineProps<{
  stageVehicle?: StageVehicle;
  darkMode?: boolean;
  hasErrors?: boolean;
}>();

const wbv = ref<WB360 | undefined>();
const setWbv = (el: HTMLElement) => (wbv.value = el as WB360);

const loadState = reactive({
  initial: false,
  low: false,
});

const ri = useResponsiveImage();

const rotationTracked = ref(false);
const { track } = useTrackingStore();

const trackRotation = ({ detail }: CustomEvent<number>) => {
  if (rotationTracked.value || detail === initialPerspective / 10) return;
  track({ label: 'stage_360', category: 'feature', action: 'dynamic_stage' });
  rotationTracked.value = true;
};

async function preloadHighResImages(e: MouseEvent) {
  const images = (e.currentTarget as WB360).images;
  const r = await Promise.allSettled(
    images.map((image) =>
      waitForLoad(image.highRes.img!, image.highRes.src) //
        .then(() => (image.highRes.isLoaded = true)),
    ),
  );
  cacheKill(r, images, 'highRes');
}

watch([wbv, ri, () => props.stageVehicle], ([wb360, responsiveWidth, stageVehicle]) => {
  if (stageVehicle && wb360) {
    const baseImageUrl = stageVehicle.baseImageUrl; // + '&ck=' + Date.now();
    const images = Array.from<unknown, ImageConfig>({ length: 36 }, (_, i) => ({
      highRes: { src: mkBBDImgUrl(baseImageUrl, i * 10, responsiveWidth) },
      lowRes: { src: mkBBDImgUrl(baseImageUrl, i * 10, 240) },
    }));

    wb360.images = images;

    // wait for all low res images to be loaded
    Promise.allSettled(images.map((image) => waitForLoad(image.lowRes!.img!))) //
      .then((r) => {
        cacheKill(r, images, 'lowRes');
        loadState.low = true;
      });

    // preload the first high res image
    waitForLoad(images[initialPerspective / 10].highRes!.img!).then(() => {
      loadState.initial = true;
      // remove the ssr placeholder image after the first image is loaded
      setTimeout(() => (placeholderImage.value = undefined), 10);
    });
  }
});

// SSR placeholder image
const placeholderImage = ref(
  aemData.renderMode !== 'csr' &&
    props.stageVehicle &&
    mkBBDResponsiveParams(props.stageVehicle.baseImageUrl, initialPerspective),
);
</script>
<template>
  <div class="owpi-threesixty-viewer" :disabled="!loadState.low">
    <wb-360-viewer
      v-stencil-ready="setWbv"
      show-hint
      legacy-assets="false"
      :loading="(!stageVehicle || !loadState.initial || !loadState.low) && !hasErrors"
      :theme="darkMode ? 'dark' : 'light'"
      :current-index="initialPerspective / 10"
      @wbchange="trackRotation"
      @touchstart.once="preloadHighResImages"
      @mouseover.once="preloadHighResImages"
    />
    <img v-if="placeholderImage" v-bind="placeholderImage" alt="Placeholder image while car is loading" />
  </div>
</template>
<style scoped lang="scss">
.owpi-threesixty-viewer {
  position: relative;

  &[disabled='true'] {
    wb-360-viewer {
      pointer-events: none;
    }
    &::after {
      content: '';
      position: absolute;
      inset: 0;
      max-width: var(--wb-grid-width);
      margin-inline: auto;
      cursor: not-allowed;
    }
  }

  // SSR placeholder image
  > img {
    position: absolute;
    inset: 0;
    top: max(-1.5vw, -30px);
    object-fit: contain;
    width: 100%;
    aspect-ratio: 16 / 9;
    z-index: 1;
    transform: scale(1.2);
  }

  wb-360-viewer {
    // avoid jump while the JS for the custom component is loading by copy the default styles
    display: block;
    padding-top: 56.25%; // 16x9
    z-index: 1;
    top: max(-1.5vw, -30px);

    &:focus {
      // This kinda breaks accessibility with tab navigation, but it also breaks the design to have the outline in the middle
      outline: none;
    }

    :deep(.wb-360-viewer__image) {
      // we're scalling the stage content to fit better the content grid
      transform: scale(1.2);
    }

    :deep(.wb-360-hint) {
      top: 55% !important;
    }
  }
}
</style>
