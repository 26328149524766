/**
 * Very minimal store implementation.
 * It mimics the API of pinia, but with a lot less features.
 * It may be replaced by pinia in the future.
 * The reason why it's not pinia is that it's not compatible with Vue 2 UMD bundle.
 */
export function defineStore<T = Store>(name: string, setup: () => T): () => T {
  return () => {
    let store: T | undefined = stores[name];
    if (store) return store;
    store = setup();
    stores[name] = store;
    return store;
  };
}

/**
 * Resets all stores by deleting all keys from the stores object.
 */
export function resetStores() {
  for (const key in stores) {
    delete stores[key];
  }
}

type Store = {
  [key: string]: unknown;
};

const stores: Record<string, any> = {};
