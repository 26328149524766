/**
 * @file This file exports functions to make HTTP requests to the backend API.
 * @packageDocumentation
 */
import { HttpClient, type FullRequestParams, type HttpResponse } from './http-client';
import { panic } from '@dh-io-owpi/shared/src/utils/panic';

let http: HttpClient | undefined;
let baseUrl: string;

/**
 * Sends an HTTP request to the backend API.
 * @param p - The request parameters.
 * @returns A promise that resolves to the HTTP response.
 * @typeParam T - The expected response body type.
 * @typeParam E - The expected error response body type.
 */
export async function request<T = any, E = any>(p: FullRequestParams): Promise<HttpResponse<T, E>> {
  if (!http) {
    http = baseUrl ? new HttpClient({ baseUrl, retries: p.retries }) : panic('API Client not initialized.');
  }
  return http.request(p);
}

/**
 * Initializes the backend API client with the given base URL.
 * @param apiBaseUrl - The base URL of the backend API.
 * @param force - Whether to force re-initialization of the API client.
 */
export function initializeApi(apiBaseUrl: string, force = false) {
  if (force) http = undefined;
  baseUrl = apiBaseUrl;
}
