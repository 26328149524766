/* v8 ignore start - nothing to test here */
// copy of the default client entry
// needed for be able to use the same entry for playwright tests
import app from './main';
import render from '$app/render/client';

import { registerComponentInRUM } from '@dh-io-owpi/shared/src/utils/ddRum';

registerComponentInRUM();

render(app.createApp, import.meta.env.APP_NAME);
