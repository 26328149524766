import type { ConnectionVehicleDataState } from '@dh-io-globalelem/vehicle-data-store-connection';
// set vehicle configuration id in vehicle data store for automated subnavigation CTAs
export async function setVehicleConfigurationId(vehicleId: string) {
  const { setVehicleConfigurationId } = await import('./vehicleDataStore.browser');
  setVehicleConfigurationId(vehicleId);
}

export async function getVehicleDataStore(fn: (state: ConnectionVehicleDataState) => void) {
  const { getVehicleDataStore } = await import('./vehicleDataStore.browser');
  getVehicleDataStore(fn);
}
