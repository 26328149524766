import { inject, shallowReactive, type InjectionKey, type Plugin } from 'vue';
import type { I18nCtx, MessageMap } from './types';
import dlv from 'dlv';
import { panic } from '../../utils/panic';

const KEY = Symbol('i18n') as InjectionKey<I18nCtx>;
const interpolationRx = /{{\s*(\w*)\s*}}/g;

export type CreateI18nOptions = { messages?: MessageMap; debug?: boolean };

export function createI18n(options: CreateI18nOptions = {}): I18nCtx & Plugin {
  const data = shallowReactive<CreateI18nOptions>(options);

  const ctx: I18nCtx = {
    t: (key, params) => {
      // return the key of missing translations in author mode, allowing the author to see the key and add it to phrase
      const translation: string | undefined = getT(data.messages, key) ?? (data.debug ? key : undefined);
      if (!translation || !params || !Object.keys(params).length) return translation;

      return translation.replace(interpolationRx, (match, prop) => params[prop] ?? match);
    },
    exists: (key) => getT(data.messages, key) !== undefined,
    setMessages(messages) {
      data.messages = messages;
    },
    setDebug(debug) {
      data.debug = debug;
    },
    get messages() {
      return data.messages;
    },
  };

  return {
    ...ctx,
    install(app) {
      app.provide(KEY, ctx);
      app.config.globalProperties.$t = ctx.t;
      app.config.globalProperties.$exists = ctx.exists;
    },
  };
}

function getT(messages: MessageMap | undefined, key: string): string | undefined {
  return messages ? dlv(messages, key) : undefined;
}

export function useI18n(): I18nCtx {
  return inject(KEY, null) ?? panic('useI18n must be called within a setup function.');
}
