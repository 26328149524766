import { computed, type Ref } from 'vue';
import type { BrandId } from '@dh-io-owpi/backend-api/src/data-contracts';
import { useAemData } from '@dh-io-owpi/shared/src/plugins/aemData';
import { generateSrcSet, patternResizeInfo } from '../utils/bbdImgUrls';
import { backplateCroppingInfo } from '../utils/bbdImgUrls/cropping-v2';
import { backplateCroppingInfoMvp } from '../utils/bbdImgUrls/cropping-mvp';
import { getAssetsForSubBrand } from '@dh-io-owpi/shared/src/assets';

export function useStageBackground(subBrand: Ref<BrandId>, campaignBgUrl: Ref<string | null>, mvp = false) {
  const aemData = useAemData();

  const srcSets = computed(() => {
    if (campaignBgUrl?.value)
      return { backplate: generateSrcSet(campaignBgUrl.value, mvp ? backplateCroppingInfoMvp : backplateCroppingInfo) };

    const { backplate, pattern } = getAssetsForSubBrand(subBrand.value, aemData.stage);

    return {
      backplate: generateSrcSet(backplate, mvp ? backplateCroppingInfoMvp : backplateCroppingInfo),
      pattern: generateSrcSet(pattern, patternResizeInfo),
    };
  });

  return srcSets;
}
