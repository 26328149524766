/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { request } from '.';
import type { RequestParams } from './http-client';

import type { Errors, GetVehicleParams, StageVehicle } from './data-contracts';
// api

/**
 * @description Provides the vehicles name, price, image url, available perspectives and states for the requested model-series containing the given equipment-ids. For AMG and Maybach the name is prefixed with the sub-brand name.
 *
 * @tags [1] Vehicle Data, [5] Component: Stage MVP
 * @name GetVehicle
 * @summary Endpoint for vehicle information.
 * @request GET:/api/v1/stage/country/{country}/language/{language}/model-series/{model-series}
 */
export const getVehicle = (
  { country, language, modelSeries, ...query }: GetVehicleParams,
  params: RequestParams = {},
) =>
  request<StageVehicle, Errors>({
    path: `/api/v1/stage/country/${country}/language/${language}/model-series/${modelSeries}`,
    method: 'GET',
    query: query,
    format: 'json',
    ...params,
  });
