/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { request } from '.';
import type { RequestParams } from './http-client';

import type { Errors, VehicleEquipmentResponse, VehicleEquipmentType, VehiclePriceInformation } from './data-contracts';
// api

/**
 * @description Provides the vehicles equipment information that is valid for the provided vehicle ID. Therefore the amount of equipment might differ from the theoretically possible amount of equipment for the given model series, but applying the returned equipment should again result in a valid vehicle configuration.
 *
 * @tags [2] Vehicle Equipment, [6] Component: SPA
 * @name GetVehicleEquipment
 * @summary Serves vehicle equipment information.
 * @request GET:/api/v1/country/{country}/language/{language}/model-series/{model-series}/vehicle/{vehicle-id}/equipment/{equipment-type}
 */
export const getVehicleEquipment = (
  country: string,
  language: string,
  modelSeries: string,
  vehicleId: string,
  equipmentType: VehicleEquipmentType,
  params: RequestParams = {},
) =>
  request<VehicleEquipmentResponse, Errors>({
    path: `/api/v1/country/${country}/language/${language}/model-series/${modelSeries}/vehicle/${vehicleId}/equipment/${equipmentType}`,
    method: 'GET',
    format: 'json',
    ...params,
  });
/**
 * @description Provides the vehicles initial-price and configuration-price for the motorization encoded in the vehicle-id. The former is the price for the default market configuration without any changes. The latter reflects all configuration changes on top of the initial configuration.
 *
 * @tags [1] Vehicle Data, [5] Component: Stage MVP
 * @name GetVehiclePrice
 * @summary Serves vehicle price information.
 * @request GET:/api/v1/country/{country}/language/{language}/model-series/{model-series}/vehicle/{vehicle-id}/price
 */
export const getVehiclePrice = (
  country: string,
  language: string,
  modelSeries: string,
  vehicleId: string,
  params: RequestParams = {},
) =>
  request<VehiclePriceInformation, Errors>({
    path: `/api/v1/country/${country}/language/${language}/model-series/${modelSeries}/vehicle/${vehicleId}/price`,
    method: 'GET',
    format: 'json',
    ...params,
  });
