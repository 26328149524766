<script lang="ts" setup>
import { computed } from 'vue';
import { useMatchMediaQuery } from '@dh-io-owpi/shared/src/composables/useMediaQuery';

defineProps<{
  price?: string;
  pricePrefix?: string;
  purchasePriceTooltip?: string;
  isPriceLoading: boolean;
  isDark: boolean;
}>();

// This prevents the tooltip to be cut off when there are large amounts of text in mobile.
const isMobile = useMatchMediaQuery('mq1', 'mq2');
const tooltipPosition = computed(() => (isMobile.value ? 'top' : 'bottom'));
</script>
<template>
  <div class="owpi-stage-price" :class="['owpi-stage-price', { 'owpi-stage-price--dark': isDark }]">
    <span v-if="price" class="owpi-stage-price__price">
      <span v-if="pricePrefix" class="wb-type-label owpi-stage-price__price-prefix">{{ $t(pricePrefix) }}</span>
      <span class="owpi-stage-price__price-value-wrapper">
        <span class="wb-type-heading-xs owpi-stage-price__price-value">{{ price }}</span>
        <wb-tooltip-host
          v-if="purchasePriceTooltip"
          :position="tooltipPosition"
          class="owpi-stage-price__price-tooltip-wrapper"
        >
          <wb-icon name="bds/question-mark-circle-outline/24" class="owpi-stage-price__price-tooltip-icon" />
          <wb-tooltip>
            {{ purchasePriceTooltip }}
          </wb-tooltip>
        </wb-tooltip-host>
      </span>
    </span>
    <wb-skeleton v-else-if="isPriceLoading" class="wb-type-heading-xs owpi-stage-price__price_sk">&nbsp;</wb-skeleton>
  </div>
</template>
<style lang="scss" scoped>
.owpi-stage-price {
  $root: &;

  &__price-prefix {
    color: var(--wb-grey-45);

    #{$root}--dark & {
      color: var(--wb-white);
    }
  }

  &__price-value-wrapper {
    display: inline-flex;
    align-items: center;
    margin-inline-start: var(--wb-spacing-xxs);
  }

  &__price-tooltip-wrapper {
    align-self: center;
    margin-inline-start: var(--wb-spacing-3xs);

    @include breakpoint-from(mq4) {
      // move up by 2px to fix the visual alignment
      margin-top: -2px;
    }
    // This will prevent the tooltip content from rendering before the wb component is hydrated
    wb-tooltip:not(.hydrated) {
      display: none;
    }
  }

  &__price-tooltip-icon {
    width: 18px;
    height: 18px;
  }

  &__price-tooltip-title {
    font-weight: bold;
  }

  &__price_sk {
    display: inline-block;
    width: 180px;
  }
}
</style>
