import type { RenderMode, SSRContext } from '@seamless/oneweb-ssr';
import type { PartialAemEnvState, Environment, AemData } from './types';

/**
 * Map the AEM environment and component data to a single object.
 */
export function mapEnvToAemData<ComponentData>(
  url: string,
  aemEnv: PartialAemEnvState,
  componentData: ComponentData,
  isAemEditMode: boolean = false,
  renderMode: RenderMode = 'csr',
): AemData<ComponentData> {
  const { country, language, vehicleData, stage } = aemEnv;

  return {
    url,
    isAemEditMode,
    renderMode,
    country: mapAemAntarcticaCountry(country, language),
    language,
    modelSeries: vehicleData.modelSeries,
    subBrand: vehicleData.subBrands?.[0] || 'MERCEDES_BENZ',
    stage: stage as Environment,
    ...componentData,
  };
}

export function mapSSRContextToAemData<ComponentData>(
  context: SSRContext<ComponentData>,
  isSSR: boolean,
): AemData<ComponentData> {
  const { runMode, wcmMode } = context.pageAEMContext;

  return mapEnvToAemData(
    context.requestUrl,
    context.pageAEMContext as any,
    context.payload,
    !isSSR && runMode == 'AUTHOR' && wcmMode == 'EDIT',
    isSSR ? 'ssr' : 'csr',
  );
}

// There is no Antarctica in the backend, so we need to replace it with valid ones
function mapAemAntarcticaCountry(country: string, language: string): string {
  if (country === 'AQ') return aqLanguageMap[language] ?? country;
  return country;
}

// map languages used on antarctica market to country codes
const aqLanguageMap: Record<string, string> = {
  de: 'DE',
  en: 'DE',
  es: 'ES',
  fr: 'FR',
  it: 'IT',
  nl: 'NL',
};
