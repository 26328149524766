import { nextTick, type ObjectDirective } from 'vue';

const addAnchorId = (el: HTMLElement, id: string): void => {
  const host =
    el.getRootNode() instanceof ShadowRoot ? ((el.getRootNode() as ShadowRoot | undefined)?.host as HTMLElement) : el;
  if (host && id) host.dataset.anchorId = id;
};

const dispatchReadyEvent = (id: string): void => {
  window.dispatchEvent(
    new CustomEvent('owc-vertical-navigation-add-section', {
      detail: {
        section: id,
      },
    }),
  );
};

export const vAnchor: ObjectDirective<HTMLElement, string> = {
  async mounted(el, binding) {
    await nextTick();
    addAnchorId(el, binding.value);
    dispatchReadyEvent(binding.value);
  },
  async updated(el, binding) {
    await nextTick();
    addAnchorId(el, binding.value);
  },
};
