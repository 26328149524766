/**
 * Returns the Image Manager query string
 *
 * https://techdocs.akamai.com/ivm/docs/imquery
 *
 * @param operators - Array of operators
 * @returns Image Manager query string
 * @example
 * getAkamaiIM([
 *   ['Crop', 100, 200],
 *   ['Resize', 500],
 * ]);
 * // 'Crop,size=(100,200);Resize=(500)'
 * @example
 * getAkamaiIM([
 *   ['Crop', 100, 200, 'East'],
 *   ['Resize', 500],
 * ]);
 * // 'Crop,size=(100,200),gravity=East;Resize=(500)'
 */
export function getAkamaiIM(operators: AkamaiOperators[]): string {
  const im: string[] = [];
  for (const op of operators) {
    const [opName, ...opArgs] = op;
    if (opName === 'Crop') {
      //Crop,width=<width_to_crop>,height=<height_to_crop>,gravity=<gravity>
      //Crop,xPosition=<x_position>,yPosition=<y_position>,width=<width_to_crop>,height=<height_to_crop>,gravity=<gravity>

      // xPosition and yPosition are optional, so we need to add undefined to the beginning of the array
      if (opArgs.length < 4) opArgs.splice(0, 0, undefined, undefined);
      const [xPosition, yPosition, width, height, gravity] = opArgs;
      const o = [
        xPosition != undefined && `xPosition=${xPosition}`,
        yPosition != undefined && `yPosition=${yPosition}`,
        `width=${width}`,
        `height=${height}`,
        gravity != undefined && `gravity=${gravity}`,
      ]
        .filter(Boolean)
        .join(',');

      im.push(`Crop,${o}`);
    } else if (opName === 'Resize') {
      //Resize,width=<resized_width>,height=<resized_height>
      const o = [opArgs[0] && 'width=' + opArgs[0], opArgs[1] && 'height=' + opArgs[1]].filter(Boolean).join(',');
      im.push(`Resize,${o}`);
    }
  }
  return im.join(';');
}

/**
 * Apply Akamai Image Manager operators to the source URL
 * @param src The source URL pointing to any image behind Akamai Image Manager
 * @param operators Array of operators to apply to the image, or a string with the Image Manager query string
 * @returns The URL with the Image Manager query string
 * @example
 * applyAkamaiIM('https://assets.web.com/images/iris.png', [['Resize', 320]]);
 * // 'https://assets.web.com/images/iris.png?im=Resize=320'
 */
export function applyAkamaiIM(src: string, operators: AkamaiOperators[] | string) {
  if (!operators.length) return src;
  if (/[?&]im=/.test(src)) throw new Error('The source URL already contains an Image Manager query string');

  return `${src}${src.includes('?') ? '&' : '?'}im=${typeof operators == 'string' ? operators : getAkamaiIM(operators)}`;
}

/**
 * Akamai Image Manager operators
 * Small subset of the available operators
 * https://techdocs.akamai.com/ivm/docs/imquery#imquery-string-examples
 */
export type AkamaiOperators =
  /**
   * Crop operation with width and height, and optional gravity
   */
  | ['Crop', width: number, height: number, gravity?: AkamaiGravity]
  /**
   * Crop operation with x, y, width, height, and optional gravity
   */
  | ['Crop', x: number, y: number, width: number, height: number, gravity?: AkamaiGravity]
  /**
   * Resize operation with width and optional height
   */
  | ['Resize', width: number, height?: number];

/**
 * Gravity options for the Crop operation in Akamai Image Manager from ImageMagick
 * https://imagemagick.org/script/command-line-options.php?#gravity
 */
export type AkamaiGravity =
  | 'Center'
  | 'East'
  | 'NorthEast'
  | 'North'
  | 'NorthWest'
  | 'SouthEast'
  | 'South'
  | 'SouthWest'
  | 'West';
