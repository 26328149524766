import type { BrandId } from '@dh-io-owpi/backend-api/src/data-contracts';
import type { Environment } from '../plugins/aemData';
import { mapValues } from '../utils/mapValues';
import { getAssetsBaseUrl } from '.';

export function getAssetsForSubBrand(subBrand: BrandId, env: Environment): Assets {
  const base = getAssetsBaseUrl(env) + 'images/backgrounds/';
  const assets = subBrandAssets[subBrand] ?? subBrandAssets.MERCEDES_BENZ;

  return mapValues(assets, (value) => base + value);
}

interface Assets {
  backplate: string;
  pattern: string;
}

const subBrandAssets: Record<BrandId, Assets> = {
  MERCEDES_AMG: {
    backplate: 'AMG/AMG_backplate.jpg',
    pattern: 'AMG/AMG_pattern.png',
  },
  MERCEDES_MAYBACH: {
    backplate: 'MAYBACH/MAYBACH_backplate.jpg',
    pattern: 'MAYBACH/MAYBACH_pattern.png',
  },
  MERCEDES_BENZ: {
    backplate: 'MB/M-B_backplate.jpg',
    pattern: 'MB/M-B_pattern_animated.webp',
  },
};
