/* v8 ignore start */

import { browser } from '../environment';

/**
 * Register the component in RUM, if available, with the component name and version from the environment.
 */
export function registerComponentInRUM() {
  const DD_RUM = browser && window.DD_RUM;
  if (DD_RUM) {
    const vehicleData = window.aemNamespace?.vehicleData;
    DD_RUM.onReady(() =>
      DD_RUM.setGlobalContextProperty('owpiFe', {
        component: import.meta.env.APP_NAME,
        version: import.meta.env.APP_VERSION,
        modelSeries: vehicleData?.modelSeries,
        subBrand: vehicleData?.subBrands?.[0] ?? 'MERCEDES_BENZ',
      }),
    );
  }
}

/**
 * Add an error to RUM, if available.
 */
export function addRUMError(error: unknown, context?: object | undefined) {
  const DD_RUM = browser && window.DD_RUM;
  if (DD_RUM) DD_RUM.onReady(() => DD_RUM.addError(error, context));
}

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    DD_RUM?: typeof import('@datadog/browser-rum').datadogRum;
  }
}
