import { browser } from '../../environment';
import type { Environment } from '../types/aem';
import type { AemData } from '../../plugins/aemData';

const i18nFallbackLocale = 'en';
export const i18nPhraseOptions = `i18next&include_empty_translations=true&fallback_locale_id=${i18nFallbackLocale}`;
export const i18nProjectId = '56e8fc1c95cde3261f163e98a69b67e0';

const prodApiUrl = 'https://api.oneweb.mercedes-benz.com/pi-api';
const nonProdApiUrl = 'https://int.api.oneweb.mercedes-benz.com/pi-api';

const nonProdEnvironments: Environment[] = ['TEST', 'INT'];

export function getApiBaseUrl({
  stage,
  _demoMode,
  _demoModeApiUrl,
}: Pick<AemData, 'stage' | '_demoMode' | '_demoModeApiUrl'>): string {
  let apiBaseUrl: string | undefined;
  if (_demoModeApiUrl) {
    // use api url defined in demo ui.
    apiBaseUrl = _demoModeApiUrl;
  } else if (!browser && !_demoMode) {
    // use api url defined in env variables.
    apiBaseUrl = process.env.PI_API_URL;
  }
  if (!apiBaseUrl) {
    // use default api url if inside browser context.
    apiBaseUrl = nonProdEnvironments.includes(stage) ? nonProdApiUrl : prodApiUrl;
  }

  return apiBaseUrl;
}
