import { type ImageCroppingInfo, CAMPAIGN_BG_WIDTH } from '.';

// displayWidth is minimum width for next MQ to ensure a sharp image when resizing
export const backplateCroppingInfoMvp: ImageCroppingInfo = {
  mq1: {
    y: 1320,
    width: 1615,
    displayWidth: 480,
    height: 2215,
  },
  mq2: {
    y: 1260,
    width: 1772,
    displayWidth: 768,
    height: 1591,
  },
  mq3: {
    y: 1150,
    width: 2304,
    displayWidth: 1024,
    height: 1130,
  },
  mq4: {
    y: 1150,
    width: 2330,
    displayWidth: 1280,
    height: 1149,
  },
  mq5: {
    y: 1170,
    width: 2312,
    displayWidth: 1440,
    height: 1095,
  },
  mq6: {
    y: 1220,
    width: CAMPAIGN_BG_WIDTH,
    height: 981,
  },
} as const;
